/**
 * Biographie Nader Abou Anas
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import Img from 'gatsby-image';
import ContainerWithSideBarre from '../components/body/ContainerWithSideBarre';
import ContentLeft from '../components/body/ContentLeft';
import ContentRight from '../components/body/ContentRight';
import Container from '../components/body/Container';
import SideBar from '../components/marketing/sideBar/SideBar';
import StickyBox from 'react-sticky-box';
import Image from '../images/photos/qui-est-nader-abou-anas.jpeg';

export function calculateAge(birthdate) {
	const birthYear = new Date(birthdate).getFullYear();
	const currentYear = new Date().getFullYear();
	return currentYear - birthYear;
}

const NaderAbouAnas = ({ data }) => {
	const birthdate = '1982-08-25';
	const age = calculateAge(birthdate);

	return (
		<Layout title="QUI EST NADER ABOU ANAS ?">
			<Seo title="Nader Abou Anas | Imam, Conférencier et Enseignant" />

			<Container>
				<ContainerWithSideBarre>
					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-8/12 stickyBox"
					>
						<ContentLeft backgroundDisplay={false}>
							<div className="mb-10 pb-5 border-b ">
								<img
									src={Image}
									className="w-full"
									alt="Nader Abou Anas Site Officiel - Cours d'islam En Ligne"
								/>
							</div>

							<div>
								<p>
									<span style={{ textDecoration: 'underline' }}>
										<strong>PRÉSENTATION :</strong>
									</span>
								</p>

								<p>Prénom&nbsp;: Nader</p>

								<p>Surnom : Abou Anas</p>

								<p>Age : {age} ans</p>

								<p>
									<span style={{ textDecoration: 'underline' }}>
										<strong>SITUATION FAMILIALE:</strong>
									</span>
								</p>

								<p>
									Notre frère est marié et est père de 4 enfants, qu’Allah les
									préserve.
								</p>

								<p>
									<strong>
										<span style={{ textDecoration: 'underline' }}>
											FONCTION
										</span>
										:
									</strong>
								</p>

								<p>
									Nader Abou Anas est actuellement imam, enseignant et
									conférencier.
								</p>

								<p>
									<strong>
										<span style={{ textDecoration: 'underline' }}>
											PARCOURS
										</span>
										:
									</strong>
								</p>

								<p>
									Le frère Nader Abou Anas est un demandeur de science et
									conférencier.
								</p>

								<p>
									Depuis le début des années 2000, le frère consacre la majorité
									de son temps à l’apprentissage des sciences islamiques et à la
									da’wah (propagation du message de l’islam).
								</p>

								<p>
									Son grand amour pour la religion lui a été transmis par Cheikh
									Hassan Bounamcha (‘Ammi Hassan) qui lui a donné l’envie de
									s’investir pour la religion d’Allah (qu’Allah le préserve).
								</p>

								<p>
									Il a suivi le cycle de 6 ans d’arabe et de sciences religieuses
									à la Madrassah de Cheikh Ayoub (qu’Allah le préserve). Cette
									école, située au 50 rue de la Chapelle 75018 Paris, dispose
									d’une méthode d’enseignement qui a fait ses preuves en France et
									à l’étranger et qui a formé nombre de membres actifs et reconnus
									dans la communauté en France.
								</p>

								<p>
									Nader Abou Anas a ainsi été initié à plusieurs branches des
									sciences islamiques: La croyance, la jurisprudence, la science
									du hadith et le Coran.
								</p>

								<p>
									Il est actuellement en classe de&nbsp;&nbsp;7ème année à la
									Madrassah de Cheikh Ayoub (qu’Allah le préserve). Pour celles et
									ceux qui ne connaîtraient pas cette école, la 7ème année propose
									l’étude de plusieurs livres de jurisprudence (Al Rissala, Al
									Mouwata, Boulough Al Maram) ou l’étude du tafsir
								</p>

								<p>
									Ainsi, Nader Abou Anas a étudié en 2012-2013 &nbsp;une partie de
									Boulough Al Maram (La réalisation du but)&nbsp;de l’Imâm Ibn
									Hajar Al-‘Asqalani (رحمه الله).
								</p>

								<p>
									Depuis l’année 2013, il étudie le Mouwatta’ de l’imam Malek
									(رحمه الله) (un cursus de 8 ans et demi de fiqh) auprès de
									Cheikh Ayyoub (qu’Allah le préserve).
								</p>
								<p>
									En février 2022 Nader Abou Anas fut diplômé par une ijaza sur le
									Mouwatta’ de l’mam Malek (رحمه الله) et les six livres de
									hadiths (الكتب الستة) par la grâce d’Allah (عزوجل). Depuis mars
									2022 Nader Abou Anas a recommencé l’étude du livre Al Mouwatta’
									de ‘l’mam Malek (رحمه الله) (cela durera environ 7 ans) avec son
									cheikh afin de consolider ses connaissances.&nbsp;
								</p>

								<p>
									<strong>
										<span style={{ textDecoration: 'underline' }}>SA VOIE</span>
										:
									</strong>
								</p>

								<p>
									La formation de la Madrassah, et surtout la compagnie de Cheikh
									Ayoub (qu’Allah le préserve), ont forgé le caractère et les
									valeurs du frère Nader, à savoir l’attachement à la sounnah du
									Prophèteﷺ, aux enseignements des pieux prédécesseurs et surtout
									l’attachement au juste milieu (al wassatiyya) en réfutant tout
									excès et toute négligence.
								</p>

								<p>
									Il suit la voie des gens de la sunna et du consensus (Ahl sunna
									wa al jamaa) selon les rites de&nbsp;l’école malékite.
								</p>

								<p>
									<strong>
										Il se définit comme musulman et ne se revendique d’aucun
										groupe particulier.
									</strong>
								</p>

								<p>
									<strong>
										<span style={{ textDecoration: 'underline' }}>
											SES DISCOURS ET SOURCES
										</span>
										:
									</strong>
								</p>

								<p>
									En 2007, le site&nbsp;
									<a
										href="http://dourous.net/"
										target="_blank"
										rel="noreferrer noopener"
									>
										dourous.net
									</a>{' '}
									qui regroupe ses conférences est lancé sur la toile.&nbsp;
								</p>

								<p>En 2011, la page Youtube : Nader Abou Anas voit le jour.</p>

								<p>
									Ses conférences sont accessibles au plus grand nombre, le but
									étant d’exposer l’islam de manière simple et précise.
								</p>

								<p>
									Ses sources sont le Coran et la Sunna avec la compréhension des
									pieux prédécesseurs.
								</p>

								<p>
									Chaque source est minutieusement vérifiée afin de vous assurer
									son authenticité, mais malgré cela, personne n’est à l’abri de
									l’erreur.
								</p>

								<p>
									A chaque citation du Coran, la référence de la sourate et du
									verset sont cités (ainsi que son exégèse si elle est utilisée).
								</p>

								<p>
									En ce qui concerne la jurisprudence, Nader Abou Anas se base
									principalement sur l’avis de l’école malékite, mais il n’omet
									pas de citer les trois autres écoles sunnites qui sont celles de
									: l’imam Abou Hanifa, l’imam Chafi’i et l’imam Ahmad rahimahoum
									Allah.&nbsp;
								</p>

								<p>
									<strong>
										<span style={{ textDecoration: 'underline' }}>
											RÉPONDRE À VOS ATTENTES
										</span>
										:
									</strong>
								</p>

								<p>
									Nader Abou Anas s’inscrit dans une démarche éducative de
									transmission de la parole d’Allah (عز و جل) et de Son Prophète
									Muhammad (صلى الله عليه و سلم). Il met à la disposition du
									public francophone des conférences audios et vidéos en langue
									française. Cette initiative est la mise en œuvre concrète d’une
									recommandation faite par le Prophète (صلى الله عليه و سلم) :
								</p>
								<p>
									« Transmettez de ma part ne serait-ce qu’un verset. » [Rapporté
									par al Boukhari].
								</p>

								<p>
									Les conférences tenues par notre frère{' '}
									<strong>Nader Abou Anas</strong>&nbsp;sont destinées à répondre
									aux attentes de toute personne désireuse d’accéder aux multiples
									richesses contenues dans les sources islamiques en matière de
									dogme (‘aquida), de jurisprudence (fiqh), de morale islamique
									(akhlaq), d’étude de la vie du Prophète Muhammad (صلى الله عليه
									و سلم) (sîra)…etc.
								</p>

								<p>
									L’objectif de ses conférences est d’encourager le public
									francophone à découvrir ou à redécouvrir l’islam à travers le
									Coran et la tradition prophétique (sunna). Que l’on soit
									arabophone ou francophone, il appartient à chacun d’entre nous
									d’arpenter la voie de la connaissance spirituelle qui libère des
									ténèbres de l’ignorance. Et quiconque recherche sincèrement à
									s’éduquer est assurément guidé.
								</p>

								<p>
									Ses conférences sont données en Île-De-France ainsi qu’en
									province et apparaissent à l’avance sur sa page Facebook et sur
									instagram.
								</p>

								<h4>
									« Et rappelle, car le rappel profite aux croyants. »{' '}
									<em>(Coran sourate 51, verset 55)</em>
								</h4>
							</div>
						</ContentLeft>
					</StickyBox>

					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-4/12 stickyBox"
					>
						<ContentRight>
							<SideBar modCategoriesDisplay={false} />
						</ContentRight>
					</StickyBox>
				</ContainerWithSideBarre>
			</Container>
		</Layout>
	);
};

export default NaderAbouAnas;
